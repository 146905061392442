class ResponsiveDemo extends HTMLElement {
    constructor() {
        super();
        this.isPlaying = true;
        this.animeId;
        this.currentStep = 0;
        this.lastTimestamp = 0;
        this.deviceDisplayDuration = 1000; // Increased for better visibility during development, adjust to 1000 later
        this.devicesArray = ['PC', 'Laptop', 'Tablet', 'Smartphone'];
        this.inactivityTimer;
        this.pauseTimer;
    }

    connectedCallback() {
        this.innerHTML = /*html*/ `
        <div id="rdd-container-wrapper overflow-hidden">
            <div id="rdd-container" class="rdd-desktop absolute transition-all duration-500 ease-in-out">
                <div id="rdd-stand" class="absolute bg-gradient-to-b from-gray-300 to-gray-200 transition-all duration-500 ease-in-out">
                    <div id="rdd-stand-hole" class="absolute bg-gray-50 dark:bg-black transition-all duration-500 ease-in-out"></div>
                </div>
                <div id="rdd-device" class="absolute bg-black transition-all duration-500 ease-in-out">
                    <div id="rdd-screen" class="absolute bg-primary-100 transition-all duration-500 ease-in-out">
                        <div id="rdd-hero" class="absolute bg-gradient-to-b from-primary-400 to-primary-100 transition-all duration-500 ease-in-out">
                            <div id="rdd-hero-title1" class="absolute bg-primary-800 opacity-50 transition-all duration-500 ease-in-out"></div>
                            <div id="rdd-hero-title2" class="absolute bg-black opacity-50 transition-all duration-500 ease-in-out"></div>
                            <div id="rdd-hero-title3" class="absolute bg-black opacity-50 transition-all duration-500 ease-in-out"></div>
                        </div>
                        <div id="rdd-screen-notch" class="absolute bg-black transition-all duration-500 ease-in-out"></div>
                        <div id="rdd-logo" class="absolute bg-primary-800 transition-all duration-500 ease-in-out"></div>
                        <div id="rdd-contact" class="absolute bg-white transition-all duration-500 ease-in-out"></div>
                        <div id="rdd-block1" class="absolute bg-white transition-all duration-500 ease-in-out">
                            <div class="rdd-block-icon absolute bg-primary-400 transition-all duration-500 ease-in-out"></div>
                            <div class="rdd-block-title absolute bg-primary-500 transition-all duration-500 ease-in-out"></div>
                        </div>
                        <div id="rdd-block2" class="absolute bg-white transition-all duration-500 ease-in-out">
                            <div class="rdd-block-icon absolute bg-primary-400 transition-all duration-500 ease-in-out"></div>
                            <div class="rdd-block-title absolute bg-primary-500 transition-all duration-500 ease-in-out"></div>
                        </div>
                        <div id="rdd-block3" class="absolute bg-white transition-all duration-500 ease-in-out">
                            <div class="rdd-block-icon absolute bg-primary-400 transition-all duration-500 ease-in-out"></div>
                            <div class="rdd-block-title absolute bg-primary-500 transition-all duration-500 ease-in-out"></div>
                        </div>
                        <div id="rdd-block4" class="absolute bg-white transition-all duration-500 ease-in-out">
                            <div class="rdd-block-icon absolute bg-primary-400 transition-all duration-500 ease-in-out"></div>
                            <div class="rdd-block-title absolute bg-primary-500 transition-all duration-500 ease-in-out"></div>
                        </div>
                        <div id="rdd-profile" class="absolute transition-all duration-500 ease-in-out">
                            <picture>
                                <img aria-hidden="true" decoding="async" src="./assets/images/portrait.png" alt="profile" loading="eager" width="208" height="208">
                            </picture>
                            <div id="rdd-profile-title" class="absolute  bg-primary-800 opacity-50 transition-all duration-500 ease-in-out">
                            </div>
                        </div>
                        <div id="rdd-textbox" class="absolute transition-all duration-500 ease-in-out">
                            <div id="rdd-textbox-title" class="absolute bg-black opacity-50 transition-all duration-500 ease-in-out"></div>
                            <div id="rdd-textbox-text1" class="absolute bg-gray-500 opacity-50 transition-all duration-500 ease-in-out"></div>
                            <div id="rdd-textbox-text2" class="absolute bg-gray-500 opacity-50 transition-all duration-500 ease-in-out"></div>
                            <div id="rdd-textbox-text3" class="absolute bg-gray-500 opacity-50 transition-all duration-500 ease-in-out"></div>
                        </div>
                    </div>
                </div>
                <div id="rdd-foot" class="absolute bg-gradient-to-b from-gray-300 to-gray-200 transition-all duration-500 ease-in-out">
                    <div id="rdd-foot-notch" class="absolute bg-gray-200 transition-all duration-500 ease-in-out"></div>
                </div>
            </div>
            <div class="hidden dark:block absolute w-full h-[85%] mb-12 bg-black/30 z-10"></div>
            <div id="rdd-nav">
                <nav class="flex items-center justify-between px-32 mb-4">
                    <a href="#" id="rdd-prev-device" class="px-4 py-2 rounded-md bg-gray-200 text-gray-700 hover:bg-gray-300 dark:bg-gray-500 dark:text-gray-900 dark:hover:bg-gray-600" aria-label="previous device">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <path d="M15 18l-6-6 6-6" />
                        </svg>
                    </a>
                    <div class="min-w-[200px] text-center">
                        <span id="rdd-current-device" class="text-xl font-semibold dark:text-white/50 bg-none">Desktop</span>
                    </div>
                    <a href="#" id="rdd-next-device" class="px-4 py-2 rounded-md bg-gray-200 text-gray-700 hover:bg-gray-300 dark:bg-gray-500 dark:text-gray-900 dark:hover:bg-gray-600" aria-label="next device">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <path d="M9 18l6-6-6-6" />
                        </svg>
                    </a>
                </nav>
            </div>
        </div>
        `;

        this.attachEventListeners();
        this.animeId = requestAnimationFrame(this.tick.bind(this)); // Bind 'this' context
        this.startAnimationAfterInactivity();

        // Set initial device - important for first load
        this.switchDevice(this.devicesArray[this.currentStep]);
    }

    disconnectedCallback() {
        cancelAnimationFrame(this.animeId);
        clearTimeout(this.inactivityTimer);
        clearTimeout(this.pauseTimer);
    }


    switchDevice(deviceType) {
        const container = this.querySelector('#rdd-container');
        if (container) {
            container.className = '';
            container.classList.add('rdd-desktop'); // Ensure default class for desktop styling
            container.classList.add(deviceType.toLowerCase());
            container.classList.add('absolute', 'transition-all', 'duration-500', 'ease-in-out'); // Re-add tailwind classes
        }

        const currentDeviceSpan = this.querySelector('#rdd-current-device');
        if (currentDeviceSpan) {
            currentDeviceSpan.textContent = deviceType;
        }
    }

    tick(timestamp) {
        if (!this.isPlaying) {
            return;
        }

        if (this.lastTimestamp === 0) {
            this.lastTimestamp = timestamp;
        }

        const elapsed = timestamp - this.lastTimestamp;

        if (elapsed >= this.deviceDisplayDuration) {
            this.currentStep = this.currentStep < this.devicesArray.length - 1 ? this.currentStep + 1 : 0;
            this.switchDevice(this.devicesArray[this.currentStep]);
            this.lastTimestamp = timestamp;
        }
        this.animeId = requestAnimationFrame(this.tick.bind(this));
    }
    pauseAnimation() {
        clearTimeout(this.pauseTimer);
        this.isPlaying = false;
        cancelAnimationFrame(this.animeId);
        this.pauseTimer = setTimeout(() => {
            this.isPlaying = true;
            this.lastTimestamp = 0;
            this.animeId = requestAnimationFrame(this.tick.bind(this));
            this.startAnimationAfterInactivity();
        }, 3000);
    }

    resetInactivityTimer() {
        clearTimeout(this.inactivityTimer);
        this.startAnimationAfterInactivity();
    }

    startAnimationAfterInactivity() {
        clearTimeout(this.inactivityTimer);
        this.inactivityTimer = setTimeout(() => {
            if (!this.isPlaying) {
                this.isPlaying = true;
            }
            this.lastTimestamp = 0;
            this.animeId = requestAnimationFrame(this.tick.bind(this));
        }, 5000);
    }

    attachEventListeners() {
        const prevDeviceBtn = this.querySelector('#rdd-prev-device');
        const nextDeviceBtn = this.querySelector('#rdd-next-device');

        prevDeviceBtn.addEventListener('click', (e) => {
            e.preventDefault();
            this.currentStep = this.currentStep > 0 ? this.currentStep - 1 : this.devicesArray.length - 1;
            this.switchDevice(this.devicesArray[this.currentStep]);
            this.pauseAnimation();
        });

        nextDeviceBtn.addEventListener('click', (e) => {
            e.preventDefault();
            this.currentStep = this.currentStep < this.devicesArray.length - 1 ? this.currentStep + 1 : 0;
            this.switchDevice(this.devicesArray[this.currentStep]);
            this.pauseAnimation();
        });
        const handleMouseOver = () => {
            clearTimeout(this.pauseTimer);
            this.resetInactivityTimer();
        };

        const handleMouseOut = () => {
            this.startAnimationAfterInactivity();
        };

        prevDeviceBtn.addEventListener('mouseover', handleMouseOver);
        nextDeviceBtn.addEventListener('mouseover', handleMouseOver);
        prevDeviceBtn.addEventListener('mouseout', handleMouseOut);
        nextDeviceBtn.addEventListener('mouseout', handleMouseOut);
    }
}

customElements.define('responsive-demo', ResponsiveDemo);